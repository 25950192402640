import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';

// import Multiselect from 'react-widgets/lib/';

import * as Styled from '../pages/page.styled';
import 'react-datepicker/dist/react-datepicker.css';

type Props = {
  input: Object,
  label: string,
  type: string,
  meta: Object,
  showTime: string,
  children: Object,
  data: string,
  placeholder: string,
  value: string,
  onClick: Function,
  valueField: Object,
  textField: Object
};

const renderInput = ({ placeholder, input, label, type, meta: { touched, error } }: Props) => (
  <div className="">
    {label && label && <Styled.FormLabel>{label}</Styled.FormLabel>}
    <Styled.FormInput {...input} type={type} placeholder={placeholder} autoComplete="off" className="form-control" />
    {touched && error && <Styled.error>{error}</Styled.error>}
  </div>
);

const renderTextArea = ({ placeholder, input, label, type, meta: { touched, error } }: Props) => (
  <div className="">
    {label && label && <Styled.FormLabel>{label}</Styled.FormLabel>}
    <Styled.FormTextarea {...input} type={type} placeholder={placeholder} className="form-control" rows="4" cols="40" />
    {touched && error && <Styled.error>{error}</Styled.error>}
  </div>
);

const renderInputWithoutLabel = ({ input, label, type, meta: { touched, error } }: Props) => (
  <div className="">
    <Styled.FormInput {...input} type={type} autoComplete="off" className="form-control pt-10" placeholder={label} />
    {touched && error && <Styled.error className="error-minus">{error}</Styled.error>}
  </div>
);

const renderMultiselect = ({ input, data, valueField, textField, meta: { touched, error } }: Props) => (
  <div>
    {/* <Multiselect
      {...input}
      onBlur={() => input.onBlur()}
      value={input.value || []}
      data={data}
      placeholder="Please select"
      valueField={valueField}
      textField={textField}
    /> */}

    {touched && error && <Styled.error>{error}</Styled.error>}
  </div>
);

const renderInputHidden = ({ input, label, type, meta: { touched, error } }: Props) => (
  <div>
    <Styled.FormInput {...input} type={type} autoComplete="off" className="form-control hidden" placeholder={label} />
    {touched && error && <Styled.error>{error}</Styled.error>}
  </div>
);

const SelectField = ({ input, label, meta: { touched, error }, children }: Props) => (
  <div className="">
    {label && label && <Styled.FormLabel>{label}</Styled.FormLabel>}
    <Styled.Box>
      <Styled.FormSelect {...input} className="form-control">
        {children}
      </Styled.FormSelect>
    </Styled.Box>
    {touched && error && <Styled.error>{error}</Styled.error>}
  </div>
);

const SelectFieldWithoutLabel = ({ input, meta: { touched, error }, children }: Props) => (
  <Styled.Box>
    <Styled.FormSelect {...input} className="form-control">
      {children}
    </Styled.FormSelect>
    {touched && error && <Styled.error>{error}</Styled.error>}
  </Styled.Box>
);

const renderDatePicker = ({ label, input: { onChange, value }, meta: { touched, error } }: Props) => (
  <div className="full-width">
    {label && label && <Styled.FormLabel>{label}</Styled.FormLabel>}
    <DatePicker
      customInput={<CustomInputForAccident />}
      name={'from'}
      selected={!value ? null : new Date(value)}
      onChange={onChange}
      dateFormat="dd/MM/yyyy"
    />
    {touched && error && <Styled.error>{error}</Styled.error>}
  </div>
);

const CustomInputForAccident = forwardRef((props: Props, ref) => (
  <input
    ref={ref}
    className="datepicker-height form-control"
    onClick={props.onClick}
    value={props.value}
    type="text"
    readOnly={true}
    placeholder="Please Enter"
  />
));

const CustomInputForExpiryPage = forwardRef((props: Props, ref) => (
  <input
    ref={ref}
    className="datepicker-height form-control"
    onClick={props.onClick}
    value={props.value}
    type="text"
    readOnly={true}
    placeholder="Please select"
  />
));

const renderExpiryDatePicker = ({ label, input: { onChange, value }, meta: { touched, error } }: Props) => {
  let previousDate = new Date();
  previousDate.setMonth(previousDate.getMonth() - 12);

  return (
    <div className="full-width">
      {label && label && <Styled.FormLabel>{label}</Styled.FormLabel>}
      <DatePicker
        customInput={<CustomInputForExpiryPage />}
        name={'from'}
        selected={!value ? null : new Date(value)}
        minDate={previousDate}
        onChange={onChange}
        dateFormat="dd/MM/yyyy"
      />
      {touched && error && <Styled.error>{error}</Styled.error>}
    </div>
  );
};

const CustomInputForTime = forwardRef((props: Props, ref) => (
  <input
    ref={ref}
    className="datepicker-height form-control"
    onClick={props.onClick}
    value={props.value}
    type="text"
    readOnly={true}
    placeholder="Please select"
  />
));

const renderTimePicker = ({ label, input: { onChange, value }, meta: { touched, error } }: Props) => (
  <div className="time-icon">
    {label && label && <Styled.FormLabel>{label}</Styled.FormLabel>}
    {/* <TimePicker clearIcon={false} onChange={onChange} format={'H:m'} time={showTime} disableClock={true} value={data} />*/}

    <DatePicker
      customInput={<CustomInputForTime />}
      name={'from'}
      selected={!value ? null : new Date(value)}
      onChange={onChange}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      dateFormat="h:mm aa"
      timeCaption="Time"
    />

    {touched && error && <Styled.error>{error}</Styled.error>}
  </div>
);

CustomInputForAccident.displayName = 'CustomInputForAccident';
CustomInputForExpiryPage.displayName = 'CustomInputForExpiryPage';
CustomInputForTime.displayName = 'CustomInputForTime';

export {
  renderInput,
  SelectField,
  renderDatePicker,
  renderTimePicker,
  renderInputWithoutLabel,
  SelectFieldWithoutLabel,
  renderInputHidden,
  renderTextArea,
  renderExpiryDatePicker,
  renderMultiselect
};

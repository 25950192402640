// @flow
import { API_BASE_PATH } from '.././config/config';

export const PATHS = {
  HOME: '/',
  BOOKING: '/booking',
  THANKYOU: '/thank-you',
  CHECKOUT: '/checkout',
  THANK: '/thank',
  // TODO: Should go to Ipifny's 404 page by using command.navigateTo(PAGE_NOT_FOUND)
  PAGE_NOT_FOUND: '/errors/404'
};

export const DEFAULT_CURRENCY_OPTIONS = {
  symbol: '$'
};

//const API_BASE_PATH = 'https://mybeautysquad.projectstatus.in:3000/v2/pwa/';

export const API_PATHS = {
  COUNTRY: `${API_BASE_PATH}countries`,
  SERVICES: `${API_BASE_PATH}services`,
  TIME_SLOT: `${API_BASE_PATH}free-slot`,
  PEAK_TIME: `${API_BASE_PATH}peaktime`,
  APPOINTMENT: `${API_BASE_PATH}appointment`,
  PAYMENT_SUCCESS: `${API_BASE_PATH}payment-success`,
  PAYMENT_FAILED: `${API_BASE_PATH}payment-failed`,
  CHECK_EMAIL: `${API_BASE_PATH}check-email`
};

// NOTE: Make sure the event keys are exactly the same as they are in the shell:
// https://bitbucket.org/accordogroup/csa.frontend/src/master/src/common/constants.js
export const MF_COMMAND_EVENTS = {
  ERROR_404: 'ERROR_404'
};

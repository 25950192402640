import axios from 'axios';
import {
  COUNTRY_NAME,
  SET_LOADER,
  CONTACT_FORM,
  SERVICES,
  TIME_SLOT,
  PEAK_TIME,
  APPOINTMENT,
  PAYMENT_SUCCESS,
  PAYMENT_FAILED,
  CHECK_EMAIL,
  SELECTED_SERVICES,
  SELECTED_PEAKPRICE,
  SELECTED_ACTUALPRICE,
  RESET_EMAIL,
  TOTALPRICE
} from './types';

import { API_PATHS } from '../constants';

export const setLoader = loader => dispatch => {
  dispatch({ type: SET_LOADER, payload: loader });
};

export const getCountry = () => async dispatch => {
  try {
    let response = [];
    if (API_PATHS.COUNTRY) {
      const instance = axios.create();
      instance.defaults.headers.common['Content-Type'] = 'application/json';
      response = await instance.get(API_PATHS.COUNTRY);
      // console.log('API Response:', response);
      dispatch({ type: COUNTRY_NAME, payload: response });
    }
  } catch (e) {
    return e;
  }
};

export const contactForm = (setLoader, formValues) => async dispatch => {
  try {
    dispatch({ type: CONTACT_FORM, payload: formValues });
  } catch (e) {
    setLoader(false);
  }
};

export const getServices = countryId => async dispatch => {
  try {
    let response = [];
    if (API_PATHS.SERVICES) {
      const instance = axios.create();
      instance.defaults.headers.common['Content-Type'] = 'application/json';
      response = await instance.post(API_PATHS.SERVICES, {
        country: countryId
      });
      dispatch({ type: SERVICES, payload: response });
    }
  } catch (e) {
    return e;
  }
};

export const getTimeSlot = (dateTime, currentTime) => async dispatch => {
  try {
    let response = [];
    if (API_PATHS.TIME_SLOT) {
      const instance = axios.create();

      instance.defaults.headers.common['Content-Type'] = 'application/json';
      response = await instance.post(API_PATHS.TIME_SLOT, {
        dateTime: dateTime,
        currentTime: currentTime
      });

      if (response && response.data.success === true) {
        let peakTimeResponse = [];
        if (API_PATHS.PEAK_TIME) {
          const instance = axios.create();
          instance.defaults.headers.common['Content-Type'] = 'application/json';
          peakTimeResponse = await instance.post(API_PATHS.PEAK_TIME, {
            dateTime: dateTime
          });

          dispatch({ type: PEAK_TIME, payload: peakTimeResponse });
        }
        //
      }

      dispatch({ type: TIME_SLOT, payload: response });
    }
  } catch (e) {
    return e;
  }
};

export const bookingForm = (setLoader, formValues) => async dispatch => {
  try {
    let response = [];
    if (API_PATHS.APPOINTMENT) {
      const instance = axios.create();
      instance.defaults.headers.common['Content-Type'] = 'application/json';
      response = await instance.post(API_PATHS.APPOINTMENT, formValues);
      console.log('form submit', response);
      dispatch({ type: APPOINTMENT, payload: response });
      return response;
    }
  } catch (e) {
    setLoader(false);
  }
};

export const paymentSuccess = () => async dispatch => {
  try {
    let response = [];
    if (API_PATHS.PAYMENT_SUCCESS) {
      const instance = axios.create();
      instance.defaults.headers.common['Content-Type'] = 'application/json';
      instance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
      response = await instance.post(API_PATHS.PAYMENT_SUCCESS, {
        booking_id: localStorage.getItem('booking_id'),
        paymentMethodId: localStorage.getItem('paymentMethodId')
      });
      dispatch({ type: PAYMENT_SUCCESS, payload: response });
      return response;
    }
  } catch (e) {
    return e;
  }
};

export const paymentFailed = () => async dispatch => {
  try {
    let response = [];
    if (API_PATHS.PAYMENT_FAILED) {
      const instance = axios.create();
      instance.defaults.headers.common['Content-Type'] = 'application/json';
      instance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
      response = await instance.post(API_PATHS.PAYMENT_FAILED, {
        booking_id: localStorage.getItem('booking_id')
      });
      dispatch({ type: PAYMENT_FAILED, payload: response });
      return response;
    }
  } catch (e) {
    return e;
  }
};

export const checkEmail = email => async dispatch => {
  try {
    console.log(email);
    let response = [];
    if (API_PATHS.CHECK_EMAIL) {
      const instance = axios.create();
      instance.defaults.headers.common['Content-Type'] = 'application/json';
      response = await instance.post(API_PATHS.CHECK_EMAIL, {
        email: email
      });
      console.log(response);
      dispatch({ type: CHECK_EMAIL, payload: response });
    }
  } catch (e) {
    return e;
  }
};

export const getSelectedServices = services => async dispatch => {
  dispatch({ type: SELECTED_SERVICES, payload: services });
};

export const getSelectedActualPrice = actulaprice => async dispatch => {
  dispatch({ type: SELECTED_ACTUALPRICE, payload: actulaprice });
};
export const getSelectedPeakPrice = peakprice => async dispatch => {
  dispatch({ type: SELECTED_PEAKPRICE, payload: peakprice });
};

export const resetEmail = () => async dispatch => {
  dispatch({ type: RESET_EMAIL, payload: '' });
};

export const getTotalPrice = total => async dispatch => {
  dispatch({ type: TOTALPRICE, payload: total });
};

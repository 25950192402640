import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import 'rc-datetime-picker/dist/picker.css';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import { LoaderScreen, Header, Footer } from '../includes';
import * as Styled from './home.styled';
import * as StyledCommon from '../page.styled';
import {
  getCountry,
  setLoader,
  contactForm,
  getServices,
  getTimeSlot,
  getSelectedServices,
  getSelectedActualPrice,
  getSelectedPeakPrice,
  getTotalPrice
} from '../../actions';
import { renderTextArea, renderInput, SelectField } from '../../constants/redux-form';
import { maxLength250, required } from '../../constants/validation';
// import 'react-widgets/dist/css/react-widgets.css';
import 'react-widgets/styles.css';
import { PATHS } from '../../constants';

/*
 TYPES
*/

type Props = {
  history: Object,

  getCountry: Function,
  getTimeSlot: Function,
  getServices: Function,
  setLoader: Function,
  isLoading: boolean,
  countryName: Function,
  contactForm: Object,
  handleSubmit: Function,
  peakTime: Object,
  datetimeSlot: Object,
  services: Object,
  countryCurrency: Object,
  getSelectedServices: Function,
  getTotalPrice: Function
};

class Home extends Component<Props> {
  constructor() {
    super();
    this.state = {
      moment: moment(),
      currentMoment: moment().add(2, 'hours'),
      minMoment: moment(),
      dateTimeMessage: '',
      services: [],
      arr: [],
      dateChange: 0,
      Total: 0,
      requireTime: '',
      requiredService: false,
      timeError: false,
      requireTimemsg: '',
      requiredQuantity: false,
      quantity: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    localStorage.removeItem('Total');
    this.props.setLoader(true);
    this.props.getCountry();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.datetimeSlot) {
      if (nextProps.datetimeSlot.success) {
        this.setState({
          isPeakTime: nextProps.peakTime.success,
          timeError: false
        });
      }
      if (nextProps.datetimeSlot.success === false) {
        this.setState({
          timeError: true
        });
      }
    }
  }

  handleChange = moment => {
    if (this.state.currentMoment._d >= moment._d) {
      this.setState({
        requireTime: true
      });
    } else {
      this.setState({
        requireTime: false,
        requireTimemsg: ''
      });
    }

    this.setState({ dateChange: 1 });

    this.setState({
      moment: moment
    });

    if (this.state.country) {
      if (this.state.peakPrice) {
        let Total = 0;
        let tempDateTime = moment._d;

        console.log(moment.date());
        var date =
          tempDateTime.getFullYear() +
          '-' +
          (tempDateTime.getMonth() + 1) +
          '-' +
          tempDateTime.getDate() +
          ' ' +
          tempDateTime.getHours() +
          ':' +
          tempDateTime.getMinutes() +
          ':' +
          tempDateTime.getSeconds();
        const appointmentTime = date;

        var tempDate = new Date();
        var currentDateTime =
          tempDate.getFullYear() +
          '-' +
          (tempDate.getMonth() + 1) +
          '-' +
          tempDate.getDate() +
          ' ' +
          tempDate.getHours() +
          ':' +
          tempDate.getMinutes() +
          ':' +
          tempDate.getSeconds();
        const currentTime = currentDateTime;

        const gettimeSlot = this.props.getTimeSlot(appointmentTime, currentTime);
        gettimeSlot
          .then(result => {
            const { quantity, selectedServices, peakPrice, actualPrice } = this.state;
            if (selectedServices && peakPrice && actualPrice && quantity) {
              let selectedServicesArr = JSON.parse(selectedServices);
              let peakPriceArr = JSON.parse(peakPrice);
              let actualPriceArr = JSON.parse(actualPrice);
              if (selectedServicesArr.length > 0) {
                if (this.props.peakTime && this.props.peakTime.success === true) {
                  Total = peakPriceArr.reduce((a, b) => a + b, 0);
                } else {
                  Total = actualPriceArr.reduce((a, b) => a + b, 0);
                }

                if (quantity !== '') {
                  Total = Total * quantity;
                }

                this.setState({
                  Total: Total
                });
              }
            }
          })
          .catch(error => {
            console.log(error);
          });

        let dateTimeMessage = '';

        if (this.props.peakTime) {
          this.setState({
            isPeakTime: this.props.peakTime.success
          });
        }

        this.setState({ dateTimeMessage: dateTimeMessage });
      } else {
        let tempDateTime = moment._d;
        console.log('moment', moment);
        console.log(moment.date());
        var date =
          tempDateTime.getFullYear() +
          '-' +
          (tempDateTime.getMonth() + 1) +
          '-' +
          tempDateTime.getDate() +
          ' ' +
          tempDateTime.getHours() +
          ':' +
          tempDateTime.getMinutes() +
          ':' +
          tempDateTime.getSeconds();
        const appointmentTime = date;

        var tempDate = new Date();
        var currentDateTime =
          tempDate.getFullYear() +
          '-' +
          (tempDate.getMonth() + 1) +
          '-' +
          tempDate.getDate() +
          ' ' +
          tempDate.getHours() +
          ':' +
          tempDate.getMinutes() +
          ':' +
          tempDate.getSeconds();
        const currentTime = currentDateTime;

        const gettimeSlot = this.props.getTimeSlot(appointmentTime, currentTime);
        gettimeSlot
          .then(result => {
            console.log(result);
          })
          .catch(error => {
            console.log(error);
          });
      }
    } else {
      let tempDateTime = moment._d;
      console.log('moment', moment);
      console.log(moment.date());
      var date =
        tempDateTime.getFullYear() +
        '-' +
        (tempDateTime.getMonth() + 1) +
        '-' +
        tempDateTime.getDate() +
        ' ' +
        tempDateTime.getHours() +
        ':' +
        tempDateTime.getMinutes() +
        ':' +
        tempDateTime.getSeconds();
      const appointmentTime = date;

      var tempDate = new Date();
      var currentDateTime =
        tempDate.getFullYear() +
        '-' +
        (tempDate.getMonth() + 1) +
        '-' +
        tempDate.getDate() +
        ' ' +
        tempDate.getHours() +
        ':' +
        tempDate.getMinutes() +
        ':' +
        tempDate.getSeconds();
      const currentTime = currentDateTime;

      const gettimeSlot = this.props.getTimeSlot(appointmentTime, currentTime);
      gettimeSlot
        .then(result => {
          console.log(result);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  getCountryId = e => {
    localStorage.removeItem('Total');

    this.props.getServices(e.target.value.split('-')[0]);

    if (e.target.value) {
      this.setState({
        Total: ''
      });
    }

    this.setState({
      country: e.target.value.split('-')[0]
    });
  };

  handleSubmit = () => {
    if (this.state.selectedServices === undefined) {
      this.setState({
        requiredService: true
      });
    }

    let requireTimemsg = '';
    if (this.state.requireTime === true || this.state.requireTime === '') {
      requireTimemsg = (
        <div className="date-time-msg">
          You cannot book an appointment within two hours. Please call our team or choose a time over two hours in
          advance.
        </div>
      );
    }

    this.setState({
      requireTimemsg: requireTimemsg
    });
  };

  onSubmit = formValue => {
    if (this.state.dateChange === 0) {
      return false;
    }

    if (this.state.requireTime === true) {
      return false;
    }

    var encodedStr = this.props.countryCurrency ? this.props.countryCurrency.symbol : '';

    this.props.getTotalPrice([this.state.Total, encodedStr]);

    if (this.state.requiredService === true) {
      return false;
    }

    if (this.state.timeError === true) {
      return false;
    }

    this.props.setLoader(true);
    formValue.quantity = 1;
    formValue.services = JSON.parse(this.state.selectedServices);

    formValue.isPeakTime = this.state.isPeakTime;

    formValue.countryCode = localStorage.getItem('countryCode');
    formValue.country = formValue.country.split('-')[0];
    const { moment } = this.state;
    let appointdt = moment._d;
    let getDate = (appointdt.getDate() < 10 ? '0' : '') + appointdt.getDate();
    let getHour = (appointdt.getHours() < 10 ? '0' : '') + appointdt.getHours();
    let getMinutes = (appointdt.getMinutes() < 10 ? '0' : '') + appointdt.getMinutes();
    var date =
      appointdt.getFullYear() +
      '-' +
      (appointdt.getMonth() + 1) +
      '-' +
      getDate +
      ' ' +
      getHour +
      ':' +
      getMinutes +
      ':' +
      appointdt.getSeconds();
    formValue.appointmentDateTime = date;
    this.props.contactForm(this.props.setLoader, formValue);
    const SelectedArrayObject = [];

    // Step 2: Convert the array to a string
    const selectedOfObjectsString = JSON.stringify(SelectedArrayObject);
    localStorage.setItem('SelectedArray', selectedOfObjectsString);
    this.setState({ moment: moment });
    this.props.history.push(PATHS.BOOKING);
  };

  handleQuantity = e => {
    let quantity = e.target.value;
    const { selectedServices, peakPrice, actualPrice } = this.state;
    if (selectedServices && peakPrice && actualPrice) {
      let selectedServicesArr = JSON.parse(selectedServices);
      let peakPriceArr = JSON.parse(peakPrice);
      let actualPriceArr = JSON.parse(actualPrice);
      if (selectedServicesArr.length > 0) {
        let Total = '';
        if (this.props.peakTime && this.props.peakTime.success === true) {
          Total = peakPriceArr.reduce((a, b) => a + b, 0);
        } else {
          Total = actualPriceArr.reduce((a, b) => a + b, 0);
        }

        if (quantity !== '') {
          Total = Total * quantity;
        }

        localStorage.setItem('Total', Total);

        this.setState({
          quantity: quantity,
          Total: Total
        });
      }
    } else {
      this.setState({ quantity: quantity });
    }
  };

  getServiceList = (e, data) => {
    let peakPrice = [];
    let actualPrice = [];
    let selectedServices = [];
    let selectedServicesSecond = []; // this array is created for frontend calculation

    e.map(p => {
      var arr = p.value.split('-');
      let _id = arr[0];
      const arrayValue = localStorage.getItem('SelectedArray');
      const selectedArrayValue = JSON.parse(arrayValue);
      let qty = 1;
      selectedArrayValue &&
        selectedArrayValue.length > 0 &&
        selectedArrayValue.forEach(item => {
          const found = _id.toString() === item._id.toString();
          if (found) {
            qty = item.qty;
          }
        });
      peakPrice.push(parseFloat(arr[1]) * qty);
      actualPrice.push(parseFloat(arr[2]) * qty);

      selectedServicesSecond.push({
        _id,
        qty: qty
      });

      const newSelectedServicesUpdatedArray = Array(Number(qty)).fill(_id);
      newSelectedServicesUpdatedArray.forEach(i => {
        selectedServices.push(i);
      });
      // selectedServices.push(arr[0]);
    });
    this.setState({
      peakPrice: JSON.stringify(peakPrice)
    });

    this.setState({
      actualPrice: JSON.stringify(actualPrice)
    });

    this.setState({
      selectedServices: JSON.stringify(selectedServices)
    });
    //this is for frontend calculation
    this.setState({
      selectedServicesSecond: JSON.stringify(selectedServicesSecond)
    });
    localStorage.setItem('SelectedArray', JSON.stringify(selectedServicesSecond));

    if (selectedServices) {
      this.setState({
        requiredService: false
      });
    }

    if (selectedServices) {
      let Total = '';
      if (this.props.peakTime && this.props.peakTime.success === true) {
        Total = peakPrice.reduce((a, b) => a + b, 0);
      } else {
        Total = actualPrice.reduce((a, b) => a + b, 0);
      }

      if (this.state.quantity !== '') {
        Total = Total * this.state.quantity;
      }

      localStorage.setItem('Total', Total);
      this.setState({
        services: e,
        Total: Total
      });
    }
  };

  getServiceList1 = (e, inp) => {
    const qty = inp.target.value;
    const pick = JSON.parse(this.state.peakPrice);
    const actual = JSON.parse(this.state.actualPrice);
    const updetPickPrice = [...pick];
    const updateActualPrice = [...actual];

    e.map(p => {
      const arr = p.value.split('-');
      const arr1 = JSON.parse(this.state.selectedServicesSecond);

      const find = arr1.find(i => i._id === arr[0]);
      const pickPri = find.qty * arr[1];
      const pickArrIndex = updetPickPrice.indexOf(pickPri);
      if (pickArrIndex > -1) {
        // only splice array when item is found
        updetPickPrice.splice(pickArrIndex, 1); // 2nd parameter means remove one item only
      }
      updetPickPrice.push(parseFloat(arr[1]) * qty);

      const acutalPri = find.qty * arr[2];
      let actualArrIndex = updateActualPrice.indexOf(acutalPri);
      if (actualArrIndex > -1) {
        // only splice array when item is found
        updateActualPrice.splice(actualArrIndex, 1); // 2nd parameter means remove one item only
      }

      updateActualPrice.push(parseFloat(arr[2]) * qty);
    });

    this.setState({
      peakPrice: JSON.stringify(updetPickPrice)
    });

    this.setState({
      actualPrice: JSON.stringify(updateActualPrice)
    });

    // id pushing operation---------
    const selectedArrayPars = JSON.parse(this.state.selectedServices);
    //first remove the id from the selectedServices to add the new according to the quantity
    const selectedServicesClone = selectedArrayPars.filter(i => i !== e[0].value.split('-')[0].toString());

    const id = e[0].value.split('-')[0].toString();

    const newSelectedServicesUpdatedArray = selectedServicesClone.concat(Array(Number(qty)).fill(id));

    this.setState({
      selectedServices: JSON.stringify(newSelectedServicesUpdatedArray)
    });
    if (this.state.selectedServices) {
      let Total = '';
      if (this.props.peakTime && this.props.peakTime.success === true) {
        Total = updetPickPrice.reduce((a, b) => a + b, 0);
      } else {
        Total = updateActualPrice.reduce((a, b) => a + b, 0);
      }
      if (this.state.quantity !== '') {
        Total = Total * qty;
      }

      const upArr = JSON.parse(this.state.selectedServicesSecond);
      const updateSecondSelectedArray = [...upArr];

      const index = updateSecondSelectedArray.findIndex(item => item._id === e[0].value.split('-')[0].toString());

      if (index !== -1) {
        updateSecondSelectedArray[index] = {
          ...updateSecondSelectedArray[index],
          qty: Number(qty)
        };
        this.setState({
          selectedServicesSecond: JSON.stringify(updateSecondSelectedArray)
        });
      }

      localStorage.setItem('Total', Total);
      localStorage.setItem('SelectedArray', JSON.stringify(updateSecondSelectedArray));

      this.setState({
        services: e,
        Total: Total
      });
    }
  };

  render() {
    const freeTimeSchedule = () => {
      if (this.props.datetimeSlot && this.props.datetimeSlot.success === false) {
        return <div className="date-time-msg">{this.props.datetimeSlot.message}</div>;
      } else {
        if (this.props.peakTime && this.props.peakTime.success === true) {
          return <div className="dateSpc">{this.props.peakTime.message}</div>;
        }

        if (this.state.requireTime === true) {
          return (
            <div className="date-time-msg">
              You cannot book an appointment within two hours. Please call our team or choose a time over two hours in
              advance.
            </div>
          );
        } else {
          return this.state.requireTimemsg;
        }

        let actualPrice = 0;
        let peakPrice = 0;
        if (this.state.selectedServices) {
          let peakPrice = this.state.peakPrice;
          let actualPrice = this.state.actualPrice;

          var peakPriceVar = peakPrice ? JSON.parse(peakPrice.split(',')) : '';
          var actualPriceVar = actualPrice ? JSON.parse(actualPrice.split(',')) : '';
          if (this.props.peakTime && this.props.peakTime.success === true) {
            this.state.Total = peakPriceVar.reduce((a, b) => a + b);
          } else if (this.props.peakTime && this.props.peakTime.success === false) {
            this.state.Total = actualPriceVar.reduce((a, b) => a + b);
          }
        }
      }
    };

    const ServiceNotAvailable = () => {
      if (this.props.serviceNotAvail && this.props.serviceNotAvail.success === false) {
        return <div className="date-time-msg">{this.props.serviceNotAvail.message}</div>;
      }
    };

    const shortcuts = {
      Today: moment(),
      Yesterday: moment().subtract(1, 'days'),
      Clear: ''
    };

    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    const countryList = () => {
      const countryUk =
        this.props.countryName && this.props.countryName?.data.find(c => c.country === 'United Kingdom');
      const countryIreland = this.props.countryName && this.props.countryName?.data.find(c => c.country === 'Ireland');

      if (countryUk && countryIreland) {
        return (
          <div>
            <Field name="country" component={SelectField} onChange={this.getCountryId} validate={required}>
              <option value="">Select Location</option>
              <option value={`${countryUk._id}-london`}>London</option>
              <option value={`${countryUk._id}-birmingham`}>Birmingham</option>
              {/* <option value={`${countryNetherlands._id}-amsterdam`}>Amsterdam</option> */}

              <option value={`${countryIreland._id}-cork`}>Cork</option>
              <option value={`${countryIreland._id}-dublin`}>Dublin</option>
            </Field>
          </div>
        );
      }
    };

    if (this.props.services && this.props.services.country) {
      var encodedStr = this.props.services.country.symbol;
      var parser = new DOMParser();
      var dom = parser.parseFromString('<!doctype html><body>' + encodedStr, 'text/html');
      var decodedCountrySymbol = dom.body.textContent;
    }

    var options = this.props.services
      ? this.props.services.services.map(s => ({
          label: s.name + ', Price: ' + decodedCountrySymbol + s.price,
          value: s._id + '-' + s.peakTimePrice + '-' + s.price,

          quantity: 1
        }))
      : '';
    if (options) {
      options.sort(function(a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
      });
    }

    if (options === '') {
      options = [];
    }

    if (this.props.countryCurrency) {
      var encodedStr = this.props.countryCurrency.symbol;
      localStorage.setItem('countryCode', this.props.countryCurrency.countryCode);

      var parser = new DOMParser();
      var dom = parser.parseFromString('<!doctype html><body>' + encodedStr, 'text/html');
      var decodedString = dom.body.textContent;
    }
    // let { selectedServices } = this.state;
    // let finArr = []
    // if (selectedServices && selectedServices.length > 0) {
    //     let serArr = JSON.parse(selectedServices);
    //     serArr.forEach(element => {
    //         let newarr = this.props.services && this.props.services.services.find(i => i._id === element);
    //         finArr.push(newarr)
    //     });
    // }
    let { selectedServicesSecond } = this.state;
    let finalSelectedService = [];
    if (selectedServicesSecond && selectedServicesSecond.length > 0) {
      let serArr = JSON.parse(selectedServicesSecond);
      serArr.forEach(element => {
        let newarr = options && options.find(i => i.value.split('-')[0] === element._id);
        if (newarr) {
          finalSelectedService.push(newarr);
        }
      });
    }
    let someSelectedValues = localStorage.getItem('SelectedArray');
    if (
      this.state.services &&
      this.state.services.length === 0 &&
      someSelectedValues &&
      someSelectedValues.length > 0
    ) {
      const SelectedArrayObject = [];
      // Step 2: Convert the array to a string
      const selectedOfObjectsString = JSON.stringify(SelectedArrayObject);
      localStorage.setItem('SelectedArray', selectedOfObjectsString);
    }
    return (
      <StyledCommon.Wrapper>
        <Header />
        <Styled.BannerHome className="banner-bg">
          <StyledCommon.Container className="pageContainer">
            <Styled.BannerText className="pink-bg">
              <Styled.BannerTextH1>Book Online</Styled.BannerTextH1>
            </Styled.BannerText>
          </StyledCommon.Container>
        </Styled.BannerHome>
        <div className="pageContainer pb-20">
          <StyledCommon.Container className="main-wrapper">
            <StyledCommon.Wrapper>
              <StyledCommon.HeadingTag>
                You can book your appointment below! To not miss out on coupon code and loyalty points please{' '}
                <a target="_blank" href="https://www.mybeautysquad.com/beautyapp" className="download-link">
                  download our app
                </a>{' '}
                to book.
              </StyledCommon.HeadingTag>
            </StyledCommon.Wrapper>

            <Styled.NotSure className="mb-30">
              <form name="contactForm" className="contactform" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <StyledCommon.BookForm>
                  <div>
                    {countryList()}
                    {ServiceNotAvailable()}
                    <div className="multiselect">
                      <ReactMultiSelectCheckboxes
                        validate={required}
                        name="services"
                        placeholder="Search Services"
                        options={options}
                        placeholderButtonLabel="Select Services"
                        onChange={this.getServiceList}
                      />
                      <StyledCommon.error>{this.state.requiredService === true ? 'Required' : ''}</StyledCommon.error>
                    </div>
                    {finalSelectedService &&
                      finalSelectedService.length > 0 &&
                      finalSelectedService.map(element => {
                        return (
                          <div className="mn-product" key={element.value}>
                            <div className="value-elm">
                              <ul>
                                <li>{element.label}</li>
                              </ul>
                            </div>
                            <div className="pr-input">
                              {/* <input
                                                            className="no-spinner"
                                                            type='tex'
                                                            placeholder='qty'
                                                            defaultValue={element.quantity}
                                                            onChange={(e) => {
                                                                const newValue = parseInt(e.target.value, 10);
                                                                if (newValue !== 0) {
                                                                    this.getServiceList1([element], e)
                                                                }
                                                            }
                                                            }
                                                        ></input> */}
                              <select
                                className="no-spinner form-control"
                                defaultValue={1}
                                onChange={e => {
                                  const selectedValue = parseInt(e.target.value, 10);
                                  if (selectedValue !== 0) {
                                    this.getServiceList1([element], e);
                                  }
                                }}
                              >
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(number => (
                                  <option key={number} value={number}>
                                    {number}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        );
                      })}

                    <DatetimePickerTrigger
                      name="appointmentDateTime"
                      shortcuts={shortcuts}
                      moment={this.state.moment}
                      minDate={this.state.minMoment}
                      onChange={this.handleChange}
                    >
                      <input type="text" value={this.state.moment.format('YYYY-MM-DD HH:mm')} readOnly />
                    </DatetimePickerTrigger>

                    {freeTimeSchedule()}
                    {/* <div>
                                            <Field name="quantity" component={SelectField} validate={required} onChange={this.handleQuantity}>
                                                <option value="">Select Quantity</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </Field>
                                            <StyledCommon.error>{(this.state.requiredQuantity === true) ? 'Required' : ''}</StyledCommon.error>

                                        </div> */}
                    <Field
                      name="postcode"
                      type="text"
                      placeholder="Post Code"
                      validate={[maxLength250, required]}
                      component={renderInput}
                    />

                    <Field
                      name="address"
                      type="text"
                      placeholder="Address"
                      validate={[maxLength250, required]}
                      component={renderTextArea}
                    />
                  </div>
                  {this.state.Total ? (
                    <div className="total-price">
                      <strong>Total Price:</strong>{' '}
                      <strong>
                        {decodedString}
                        {this.state.Total}
                      </strong>
                    </div>
                  ) : (
                    ''
                  )}
                </StyledCommon.BookForm>
                <div className="text-center">
                  <StyledCommon.InnerSmallButton
                    onClick={this.handleSubmit}
                    className="pink-btn no-hover btn-height d-inline-flex align-center just-cont-center p-5 mb-0 mt-5"
                  >
                    Submit
                  </StyledCommon.InnerSmallButton>
                </div>
              </form>
            </Styled.NotSure>
          </StyledCommon.Container>
        </div>
        <Footer />
      </StyledCommon.Wrapper>
    );
  }
}
const mapStateToProps = state => {
  return {
    countryName: state.content.countryName ? state.content.countryName.data : '',
    services: state.content.services ? (state.content.services.data ? state.content.services.data.data : '') : '',
    datetimeSlot: state.content.timeSlot ? state.content.timeSlot.data : '',
    datetimeSlotResult: state.content.timeSlot ? state.content.timeSlot.data : '',
    countryCurrency: state.content.services
      ? state.content.services.data
        ? state.content.services.data.data.country
        : ''
      : '',
    peakTime: state.content.peakTime ? state.content.peakTime.data : '',
    isLoading: state.content.isLoading,
    fetchActualPrice: state.content.actualPrice,
    fetchPeakPrice: state.content.peakPrice,
    serviceNotAvail: state.content.services ? state.content.services.data : ''
  };
};

export default connect(mapStateToProps, {
  getCountry,
  setLoader,
  contactForm,
  getServices,
  getTimeSlot,
  getSelectedServices,
  getSelectedActualPrice,
  getSelectedPeakPrice,
  getTotalPrice
})(reduxForm({ form: 'contactForm' })(Home));
